import React, { useEffect } from "react";

const BookingSec = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);
      }, []);
    
      return (
        <>
          <p className="text-center h3 mb-lg-4"><b>Schedule A Meeting</b></p>
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/mathi-pixelweb/30min"
            style={{ minWidth: "320px", height: "630px" }}
          ></div>
        </>
      );
    };

export default BookingSec;