import * as React from "react"

import Layout from "../components/layout"

import { Helmet } from "react-helmet"
import ContactForm from "../components/Contactus/ContactForm"
import PortfolioSection from "../components/Aboutus/Portfolio"




const Contactus = () => (
  <section className="contus">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/contact-us/" />
        <title>Contact Us for Crypto Business Solutions | Coinsclone</title>
        <meta name="description" content="Contact us for Crypto business solutions, cryptocurrency and bitcoin exchange clone script, White label cryptocurrency exchange software, Crypto payment gateway and Wallet for your business. Call us now!" />
        <meta name="keywords" content="Contact for crypto exchange business, bitcoin exchange software, cryptocurrency exchange software, White Label Crypto Exchange Software Development, Cryptocurrency payment gateway, Crypto wallet, DeFi Exchange Development, NFT Marketplace Development" />
      </Helmet>
      <ContactForm />
      <PortfolioSection />
    </Layout>
  </section>
)

export default Contactus
